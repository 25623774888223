<template>
    
    <tabla-datos 
        stripedRows
        :value="expedientesFiltrados" 
        :loading="loading"
        :total="total"
        paginator
        :rows="15"
        :rowsPerPageOptions="[15, 25, 50]"
        tableStyle="min-width: 50rem">
        <template #empty> No hay datos. </template>
        <Column>
            <template #header>
                <div class="d-flex justify-content-between">                    
                   Nº Expediente
                </div>
            </template>
            <template #body="{ data }">
                {{ data.codigo }}
            </template>
        </Column>
        <Column>            
            <template #body="{ data }">
                <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="confirmar(data)">Confirmar</button>
            </template>
        </Column>
        <Column>
            <template #header>
                <div class="d-flex justify-content-between">                    
                   Fecha creación de visita
                </div>
            </template>
            <template #body="{ data }">
                {{ data.fecha_crear_visita }}
            </template>
        </Column>
        <Column>
            <template #header >
                <div class="d-flex justify-content-center">                    
                   Fecha Cita y hora
                </div>
            </template>
            <template #body="{ data }">
                <div style="align-items: center; display: flex; flex-flow: row wrap; gap: 15px;">
                    <div>
                        <Calendar dateFormat="dd/mm/yy" v-model="data.fecha_inicio" id="calendar-24h" showTime hourFormat="24" />
                    </div>
                    <div>
                        <Calendar dateFormat="dd/mm/yy" v-model="data.fecha_fin" id="calendar-24h" showTime hourFormat="24" />
                    </div>
                </div>
            </template>
        </Column>
        <Column>
            <template #header>
                <div class="d-flex justify-content-between">                    
                   Datos del asegurado
                </div>
            </template>
            <template #body="{ data }">
                <p v-html="data.cliente"></p>
            </template>
        </Column>
        <Column>
            <template #header>
                <select class="form-control" v-model="OperarioSeleccionado">
                    <option value="">Operario</option>
                    <option v-for="operario in operarios" :key="operario.id" :value="operario.id">{{ operario.nombre }}</option>
                </select>
            </template>
            <template #body="{ data }">
                <p v-html="data.operario"></p>                
            </template>
        </Column>
        <Column>
            <template #header>
                <div class="d-flex justify-content-between">                    
                   Resumen de parte de trabajo
                </div>
            </template>
            <template #body="{ data }">
                {{ data.descripcion_visitadores }}
            </template>
        </Column>
    </tabla-datos>   
</template>
   
   <script>
   import Calendar from 'primevue/calendar';
import { PwgsApi } from '../../../services/PwgsApi';
   import DataTable from 'primevue/datatable';
   
   export default {
       components: {         
           "tabla-datos": DataTable,
           Calendar,
       },
       props:{
         tipo_servicio: String
       }
       ,
       computed:{
        expedientesFiltrados(){
            let filtrados = this.expedientes;
            if(this.OperarioSeleccionado){
                filtrados = filtrados.filter(exp => exp.id_operario == this.OperarioSeleccionado);
            }
            return filtrados;
        }
       },
       data() {
           return {
            operarios:[],
            OperarioSeleccionado:'',
            checkGlobal: false,
            total: '',
            expedientes: [], 
            loading:false,                            
           }
       },
       methods: {
        async confirmar(exp){
            const api = new PwgsApi();
            var fechainicioconvertida = this.convertir(exp.fecha_inicio);
            var fechafinconvertida = this.convertir(exp.fecha_fin);
            await api.put('/planning-pwgs/'+exp.id_servicio+'/confirmar-visita/'+exp.id_aviso,{fecha_cita_inicio:fechainicioconvertida.split(', ')[0], fecha_cita_fin:fechafinconvertida.split(', ')[0], hora_cita_inicio:fechainicioconvertida.split(', ')[1], hora_cita_fin:fechafinconvertida.split(', ')[1]});
            this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Visita confirmada correctamente', life: 2000 });
            this.obtenerExpedientes();
            this.OperarioSeleccionado='';
        },
        convertir(fecha){
            return fecha.toLocaleString();
        },
        convertirAFecha(fechaStr) {
            // Asume que el formato es "dd/mm/yyyy hh:mm"
            const [fecha, hora] = fechaStr.split(' ');
            const [dia, mes, año] = fecha.split('/');
            const [horas, minutos] = hora.split(':');
            
            return new Date(año, mes - 1, dia, horas, minutos);
        },
           async obtenerExpedientes() {
                this.loading = true;
               const api = new PwgsApi;       
               var aux = await api.post('avisos/visitas/'+this.tipo_servicio);    
                this.expedientes = aux.datos.map(expediente => {
                    return {
                        ...expediente,
                        fecha_inicio: this.convertirAFecha(expediente.fecha_inicio)
                    };
                });
                this.expedientes = this.expedientes.map(expediente => {
                    return {
                        ...expediente,
                        fecha_fin: this.convertirAFecha(expediente.fecha_fin)
                    };
                });
               this.total = aux.n_total_registros;
               console.log('expeds', this.expedientes);
               this.loading = false;
           },
           async obtenerOperarios() {
            const api = new PwgsApi();
            var aux = await api.get('operarios/simple');
            this.operarios = aux.datos;
           },
           seleccionartodo(){
            for(let expediente of this.expedientes){
                expediente.seleccionado = this.checkGlobal;
            }
           }
       },
       created() {
           this.obtenerExpedientes();
           this.obtenerOperarios();
       },        
       watch:{
        checkGlobal(){            
            this.seleccionartodo();            
        }
       }
   }
   </script>
   <style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   
   </style>